import React from 'react';
import { TwoCol } from '../style/TwoCol';
import styled from 'styled-components';
import { InternalPage } from '../style/InternalPage';
import { graphql, useStaticQuery } from 'gatsby';
import { Attorneys } from '../graphql/types';

export type Props = {};

export const Person = styled(TwoCol)`
	flex-direction: row-reverse;
	&:nth-child(2n) {
		flex-direction: row;
	}
	div {
		width: 55%;
		&:first-child {
			width: 42%;
			img {
				width: 100%;
			}
		}
		p {
			font-size: 0.9em;
			line-height: 1.5em;
		}

		@media (max-width: 675px) {
			width: 100%;
			&:first-child {
				width: 100%;
			}
		}
	}

	margin-bottom: 100px;
`;

const Component: React.FC<Props> = props => {
	const result: Attorneys = useStaticQuery(graphql`
		query Attorneys {
			allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/attorney/" } }) {
				edges {
					node {
						frontmatter {
							full_name
							position
							email
							photo
							bio
							priority
						}
					}
				}
			}
		}
	`);

	const attorneys = result.allMarkdownRemark.edges
		.map(a => a.node.frontmatter)
		.sort((a, b) => a.priority - b.priority);

	return (
		<InternalPage>
			<section>
				{attorneys.map((a, i) => (
					<Person key={i}>
						<div>
							<img src={a.photo} />
						</div>
						<div>
							<h1 className='big'>
								{a.full_name}
								<span className='sub'>{a.position}</span>
							</h1>
							<p>{a.bio}</p>
							<p>
								<a href={`mailto:${a.email}`}>
									Email {a.full_name.split(' ')[0]}
								</a>
							</p>
						</div>
					</Person>
				))}
			</section>
		</InternalPage>
	);
};

export default Component;
