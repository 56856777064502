import styled from 'styled-components';

export const TwoCol = styled.div`
	display: flex;
	justify-content: space-between;
	& > div {
		width: 48%;
	}
	@media (max-width: 675px) {
		display: block;
		& > div {
			width: 100%;
			&:first-child {
				margin-bottom: 30px;
			}
		}
	}
`;
